import React from "react";

import Image from "components/Image";
import Link from 'components/Link'

import zparse from "helpers/zparse";

const FeaturedContent = props => {
    const { image, title, body, link, linkText } = props;

    if (!body && !title) return null;

    return (
        <section id="featured-content">
            <div className="grid-container">
                <div className="grid-x grid-padding-x small-text-center align-middle small-align-center">
                    <div className="cell small-10 medium-6 small-order-2 medium-order-1">
                        <div className="featured-content">
                            <h2>{title}</h2>
                            <div className="body">
                                {zparse(body)}    
                            </div>
                            {link && linkText && <Link className="button" to={link}>{linkText}</Link>}
                        </div>
                    </div>
                    <div className="cell small-10 medium-6 small-order-1 medium-order-2">
                        <Image className="featured-image" src={image} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedContent;