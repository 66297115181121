import React from "react";
import Slider from "react-slick";
import { Location } from '@reach/router';

import DataProvider from 'providers/data';
import zparse from "helpers/zparse";
import useWindowDimensions from "hooks/useWindowDimensions";

import Link from 'components/Link'

const MastheadHandler = props => {

	let mastheads = props.data

	if ((props.providerResponse.data.length > 0) && props.providerStatusText === 'LOADED') {
		mastheads = props.providerResponse.data;
	}

	const { isMobile } = useWindowDimensions();
	
	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		// adaptiveHeight: true,
	};
	
	return (
		<section id="masthead">
			<Slider {...settings}>
				{mastheads.length && mastheads.map((masthead, index) => (
					<div key={`masthead-${index}`}>
						<div className="slideWrapper" style={{ backgroundImage: `url('${!isMobile ? masthead.image : masthead.mobileImage}')` }}>
							<div className="grid-container">
								<div className="grid-x grid-padding-x align-middle">
									<div className="cell small-12 medium-6">
										<div className="slideInnerWrapper">
											{index === 0 ? <h1>{zparse(masthead.title)}</h1> : <div className="h1">{zparse(masthead.title)}</div> }
											{masthead.description && <p>{masthead.description}</p>}
											{masthead.link && <Link className="button" to={masthead.link}>{masthead.linkText ? masthead.linkText : "Learn More"}</Link>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</Slider>
		</section>
	);
}

const Masthead = props => {
	return (
		<Location>
			{locationProps => <DataProvider liveRefresh={true} type="MastheadSlide" apiParams={{ approved: true, archived: false, deleted: false, _sort: "sort", _order: "asc" }}><MastheadHandler {...props} location={locationProps.location} /></DataProvider>}
		</Location>
	)
}

export default Masthead