import React from "react"
import { graphql } from 'gatsby';

import Layout from 'templates/Layout';
import Masthead from 'templates/home/Masthead';
import QuickLinks from 'templates/home/QuickLinks';
import FeaturedContent from 'templates/home/FeaturedContent';
import FeaturedStaff from 'global/FeaturedStaff';
import DataProvider from 'providers/data';



const IndexPage = (props) => {
	const { edges } = props.data.allContentPage;
	const { edges: mastheadEdges } = props.data.allMastheadSlide;
	const nodes = edges.map(({ node }) => node);
	const mastheads = mastheadEdges.map(({ node }) => node);
	let staff = []

	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}

	const featuredContent = {
		image: page?.fcImage,
		title: page?.fcTitle,
		body: page?.fcBody,
		link: page?.fcLink,
		linkText: page?.fcLinkText
	}

	staff = page?.Staff.map(stf => stf?.object);

	return (
		<>
			<Layout>
				{mastheads.length > 0 && <Masthead data={mastheads} />}
				{page?.quicklinks.length > 0 && <QuickLinks items={page.quicklinks} />}
				<FeaturedContent {...featuredContent} />
				{(page?.Staff.length > 0 && props.providerStatusText === 'LOADED') && <FeaturedStaff members={staff} slideButtonText={page.featuredSlideText} slideButtonUrl={page.featuredSlideUrl} />}
			</Layout>
		</>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: "/", _join: "Staff" }}><IndexPage {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query Homepage {
	allContentPage(filter: {uri: {eq: "/"}}) {
	  edges {
		node {
		  Staff {
			object {
			  firstName
			  lastName
			  permalink
			  photo
			  position
			  bio
			}
		  }
          fcImage
          fcTitle
          fcBody
          fcLink
          fcLinkText
		  quicklinks
		  featuredSlideText
		  featuredSlideUrl
		  meta
		}
	  }
	}
	allMastheadSlide(
		filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
		sort: {order: ASC, fields: sort}
	) {
	  edges {
		node {
		  image
		  title
		  description
		  mobileImage
		  link
		  linkText
		}
	  }
	}
  }
   
`
