import React from "react";
import zparse from "helpers/zparse";
import Link from 'components/Link'
import Image from 'components/Image'
import FadeIn from 'components/lib/FadeIn'

const QuickLinks = ({ items }) => {

    if (!items?.length) return null;
    
    return (
        <section id="quick-links">
            <div className="grid-container">
                <div className="grid-x grid-margin-x align-justify">
                    {items.map(item => {
                        return (
                            <FadeIn key={item.title} className={`cell small-10 medium-4`}>
                                <div className="quick-link">
                                    <Image className="image" src={item.icon} alt="" />
                                    <div className="title">{zparse(item.title)}</div>
                                    <div className="body">{zparse(item.body)}</div>
                                    {item.link && <Link className="button show-for-small-only" to={item.link}>{item.linkText ? item.linkText : "Learn More"}</Link>}
                                </div>
                            </FadeIn>
                        )
                    })}
                </div>
                <div className="grid-x grid-margin-x align-justify show-for-medium">
                    {items.map(item => {
                        return (
                            <FadeIn key={`link-${item.title}`} className={`cell small-10 medium-4`}>
                                <div className="quick-link">
                                    {item.link && <Link className="button" to={item.link}>{item.linkText ? item.linkText : "Learn More"}</Link>}
                                </div>
                            </FadeIn>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default QuickLinks;